@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&family=Montserrat:wght@600&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes pulse {
  0%, 100% { opacity: 0.05; }
  50% { opacity: 0.15; }
}

.network-bg {
  opacity: 0.1;
  animation: pulse 6s ease-in-out infinite;
  background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23a0aec0' fill-opacity='0.1'%3E%3Cpath d='M20 18v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-20V0h-2v2h-4v2h4v4h2V4h4V2h-4zM2 18v-4H0v4h-2v2h2v4h2v-4h4v-2H2zM2 2V0H0v2h-2v2h2v4h2V4h4V2H2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hero-section {
  position: relative;
  overflow: hidden;
  background: linear-gradient(135deg, #3b82f6, #8b5cf6); /* Diagonal gradient */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.hero-section h1 {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  color: #ffffff; /* White text */
  text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
  letter-spacing: 0.5px;
}

.hero-section p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: #fde047; /* Yellow text, equivalent to text-yellow-300 */
  letter-spacing: 0.5px;
}

.cta-button {
  background-color: #10b981;
  color: white;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  padding: 12px 24px;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cta-button:hover {
  background-color: #059669;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}
